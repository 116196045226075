import axiosClient from './axiosClient';

export const getListQuote = (dataParam?: any) => {
    console.log(dataParam, 'api file');
    const url = `${process.env.REACT_APP_PUBLIC_URL}/quotes/api/quotes`;
    const token = localStorage.getItem('access_token');
    return axiosClient.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        // params: dataParam
    });
};
