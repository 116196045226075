import React from 'react';
import DashboardStyle from './styled';
import images from 'src/assets/image';
import icons from 'src/assets/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import ViewTableComponent from './listQuotesComponent/index';
import { Popover } from 'antd';
import { useDispatch } from 'react-redux';
import { authExited } from 'src/redux/actions/authenAction';
import { useHistory } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const DashboardComponent = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const token = localStorage.getItem('access_token');

    const decoded = jwtDecode(`${token}`) as {
        family_name: string;
        given_name: string;
    };
    const fullName = `${decoded.given_name} ${decoded.family_name}`;

    const logOutBtn = () => {
        localStorage.removeItem('access_token');
        dispatch(authExited(false));
        history.push('/login');
    };
    const viewLogOutBtn = () => {
        return <span onClick={logOutBtn}>Log out</span>;
    };

    return (
        <DashboardStyle>
            <div className="menuDashboard">
                <img alt="" className="LogoIMG" src={images.logoYGT} />
                <div className="menuBtn">
                    <div className="btnQuotes">
                        <img alt="" className="iconfile" src={icons.iconFile} />
                        <div>Quotes</div>
                        <img
                            alt=""
                            className="iconfile"
                            src={icons.arrowRight}
                        />
                    </div>
                </div>
                <Popover
                    className="logOutBtn"
                    placement="right"
                    content={viewLogOutBtn}
                >
                    <div className="btmAdmin">
                        <FontAwesomeIcon
                            className="avatar"
                            icon={faCircleUser}
                        />
                        <div>
                            <div className="nameSeller">{fullName}</div>
                            <div className="position">Sale Expert</div>
                        </div>
                        <img
                            alt=""
                            className="iconfile"
                            src={icons.arrowDown}
                        />
                    </div>
                </Popover>
            </div>
            <div className="tableDashboard">
                <div className="showNameSeller">Hi{" "}{fullName}</div>
                <div className="table">
                    <ViewTableComponent />
                </div>
            </div>
        </DashboardStyle>
    );
};

export default DashboardComponent;
