import { call, put, takeLatest } from 'redux-saga/effects';
import { authExited, LOGIN } from '../actions/authenAction';
import { loginApi } from 'src/api/apiLogin';
import { toast } from 'react-toastify';

function* loginSaga(action: any) {
    try {
        // Loading.show()
        const response: Promise<any> = yield call(loginApi, action.payload);
        const { access_token }: any = response || {};
        if (access_token) {
            localStorage.setItem('access_token', access_token);
            // Map data to action redux
            yield put(authExited(true));
            action.payload?.history.push('/dashboard');
            toast.success('login success')
        } else {
            action.payload?.history.push('/login');
            toast.error('login false')
        }
    } catch (error) {
        console.log(error, 'ERROR SAGA');
        action.payload?.history.push('/login');
        toast.error('Associated RES profile not found, please ask your administrator for more information')
    } finally {
        // Loading.hide()
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default function* () {
    yield takeLatest(LOGIN, loginSaga);
}
